import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { EyeIcon } from '@heroicons/react/24/outline';
import { useUser } from '../../contexts/UserContext';
import { ProfileMenu } from './components/ProfileMenu';
import { Notification } from './components/Notification';
import { AddMember } from './components/AddMember';

export const Topbar = () => {
  const { user } = useUser();
  const location = useLocation();

  if (location.pathname.includes('demo') && location.pathname !== '/demo/live') return null;

  return (
    <div>
      <Disclosure as='nav' className='bg-perception-black-800'>
        <div className='mx-auto'>
          <div className='flex justify-between items-center h-8'>
            <LeftSideHeading />
            <div className='hidden sm:ml-6 sm:flex sm:items-center'></div>
            <RightSideHeading user={user} />
          </div>
        </div>
      </Disclosure>
    </div>
  );
};

const LeftSideHeading = () => {
  const location = useLocation();
  const isOperatorPage = location.pathname.includes('operator') || location.pathname.includes('interface');

  return (
    <div className='flex h-full'>
      <div className='flex justify-center items-center font-bold'>
        <Link className='bg-perception-blue text-white flex items-center h-full' to='/'>
          <div className='flex px-4'>
            <EyeIcon className='h-6 w-6' />
            <p className='pl-2'>Perception</p>
          </div>
        </Link>
        <p className='text-white font-semibold ml-4'>{isOperatorPage && 'Operateur'}</p>
      </div>
    </div>
  );
};

const RightSideHeading = ({ user }) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const location = useLocation();
  const isOperatorPage = location.pathname.includes('operator') || location.pathname.includes('interface');

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  if (user) {
    return (
      <div className='hidden sm:ml-6 sm:flex sm:items-center'>
        {!isOperatorPage && (
          <>
            <div className='mr-2'>
              <AddMember />
            </div>
            <div className='mr-2'>
              <Notification />
            </div>
            <ProfileMenu />
          </>
        )}
        {isOperatorPage && (
          <div className='mr-4 text-white font-semibold'>
            {currentDateTime.toLocaleDateString()}
            <span className='mr-3'></span>
            {currentDateTime.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </div>
        )}
      </div>
    );
  }
};
