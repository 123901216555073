import { InputSelect } from '../../components/forms/inputs/InputSelect';
import { Button } from '../../components/buttons/Button';
import { useEffect, useState } from "react";
import { postData, useGetData } from "../../services/api/api-tools";
import LogoPerception from '../../assets/images/logos/logo-blue-black.png';
import LogoTAG from '../../assets/images/logos/TAG_Heuer_Logo.png';
import { useSocket } from "../../contexts/SocketContext";
import { formatSecondsToMinutesSeconds } from "../../helpers/date/date-helper";
import {
  ArrowPathIcon,
  CheckCircleIcon,
  CheckIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon, InformationCircleIcon,
  XMarkIcon
} from "@heroicons/react/20/solid";
import { useAlert } from "../../contexts/AlertContext";

export const TAGUSLivePage = () => {

  const { socket, subscribeToEvent, unsubscribeFromEvent, sendEvent } = useSocket();
  const { showAlert } = useAlert();

  const { data: patterns } = useGetData('patterns', `patterns`);

  const [selectedPattern, setSelectedPattern] = useState(null);
  const [isStarted, setIsStarted] = useState(false);

  const [stepsList, setStepsList] = useState([]);
  const [totalCleaningActions, setTotalCleaningActions] = useState(0);
  const [totalCleaningDuration, setTotalCleaningDuration] = useState(0);
  const [currentStep, setCurrentStep] = useState(null);
  const [errorNextStep, setErrorNextStep] = useState(null);
  const [synthesis, setSynthesis] = useState(null);
  const [progress, setProgress] = useState(0);
  const [confidences, setConfidences] = useState([]);
  const [maxValidatedStepIndex, setMaxValidatedStepIndex] = useState(-1);
  const [hasBackwork, setHasBackWork] = useState(false);

  const getMissingValidatedSteps = (stepsList) => {
    return stepsList.filter((step) => step.state !== 1);
  };

  const handleStart = () => {
    if(selectedPattern !== null)
      setIsStarted(true);

    /* Send pattern data to the engine */
    const selectedPatternData = selectedPattern.pattern_event_types
      .sort((pet1, pet2) => pet1.time - pet2.time)
      .map((pet, index) => {
      return {
        index: index,
        subtype: pet.event_type.subtype,
        name: pet.event_type.name,
        content: pet.event_type.content,
        comment: pet.comment,
        state: 0,
        count: 0
      };
    });

    setStepsList(selectedPatternData);

    const stepsToSend = selectedPatternData.map((step) => step.name);
    console.log(stepsToSend);

    sendEvent('selected_pattern', stepsToSend);
  };

  const resetInterface = () => {
    for(let i=0; i<stepsList.length; i++){
      stepsList[i].state = 0;
      stepsList[i].count = 0;
    }
    setStepsList([...stepsList]);
    setCurrentStep(null);
    setTotalCleaningDuration(0);
    setTotalCleaningActions(0);
    setErrorNextStep(null);
    setSynthesis(null);
    setProgress(0);
    setConfidences([]);
    setMaxValidatedStepIndex(-1);
    setHasBackWork(false);
  };

  useEffect(() => {
    if (!socket) return;

    const handleStepValidated = (stepData) => {
      console.log('Received validated step:', stepData);
      const type = stepData.type;

      // Get the step corresponding to the received index
      if(!type || stepsList.length === 0 || stepData.index >= stepsList.length){
        console.error('Validated step out of the list.');
        return;
      }

      if(type === 'step'){
        // Check for step error
        // const nextStepToValidate = getNextStepToValidate();
        // if(nextStepToValidate !== null && stepData.index > nextStepToValidate.index){
        //   setErrorNextStep(nextStepToValidate);
        // }
        // else{
        //   setErrorNextStep(null);
        // }

        // Update current step
        if(stepData.state === 2)
          setCurrentStep(stepsList[stepData.index]);
        else
          setCurrentStep(null);

        // Update pattern steps list
        stepsList[stepData.index].state = stepData.state;
        if(stepData.state === 1)
          stepsList[stepData.index].count += 1;
        setStepsList([...stepsList]);

        // Check for backwork
        const newMaxValidatedStepIndex = Math.max(stepData.index, maxValidatedStepIndex);
        setMaxValidatedStepIndex(newMaxValidatedStepIndex);
        if(stepData.index < newMaxValidatedStepIndex)
          setHasBackWork(true);

        // Update progress percentage
        setProgress(Math.floor((stepData.index + 1) / stepsList.length * 100));

        // Check if the pattern is completed
        if(stepData.index === stepsList.length - 1 && stepData.state === 1){
          setTimeout(() => {
            setSynthesis({missingSteps: getMissingValidatedSteps(stepsList)});
            setTimeout(() => {
              setSynthesis(null);
            }, 15000);
          }, 2000);
        }
      }
      else if(type === 'cleaning'){
        setTotalCleaningActions(totalCleaningActions => totalCleaningActions + 1);
        setTotalCleaningDuration(stepData.total_duration);
      }
      else if(type === 'reset'){
        resetInterface();
      }

    };

    const handleReceivedConfidences = (confidences) => {
      setConfidences(confidences['conf_list']);
    };

    subscribeToEvent('step_validated', handleStepValidated);
    subscribeToEvent('steps_conf', handleReceivedConfidences)

    return () => {
      unsubscribeFromEvent('step_validated', handleStepValidated);
      unsubscribeFromEvent('steps_conf', handleReceivedConfidences);
    };
  }, [socket, stepsList]);

  const handleRaiseError = () => {
    console.log('New error raised.');
    postData('live_errors', {date: Math.floor(Date.now() / 1000), pattern: `/api/patterns/${selectedPattern?.id}`});
    showAlert('success', 'The error has been reported.')
  };

  const handleCloseSynthesis = (e) => {
    e.stopPropagation();
    resetInterface();
    setSynthesis(null);
  };

  return (
    <div className='h-full overflow-hidden bg-gray-100'>
      <div className='flex flex-col items-center justify-center w-full h-full'>
        {
          isStarted && selectedPattern !== null ?
            <div className="w-full h-full flex p-4">

              {/* Left column */}
              <div className="w-full flex flex-col gap-4">

                {/* Header with selected pattern name */}
                <div className="bg-perception-blue p-4 text-white text-center rounded-md">
                  <p className="text-xl">Selected manufacturing process:</p>
                  <p className="text-3xl">
                    {selectedPattern.name}
                  </p>
                </div>

                {/* Completed steps */}
                <div className="h-4/6 flex gap-4">
                  <div className="h-full w-1/2 bg-gray-200 overflow-y-auto rounded-md">
                    <table>
                      <thead>
                        <tr>
                          <th className='p-4 text-left'>Step</th>
                          <th className='p-4 text-left'>Progress</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        stepsList.map((step) => (
                          <tr key={step.index}>
                            <td className='p-4'>{step.content}</td>
                            {
                              step.state === 1 && step.count === 1 ?
                                <td className='p-4 text-green-600 font-bold'>Validated</td>
                              : step.state === 1 && step.count > 1 ?
                                <td className='p-4 text-yellow-500 font-bold'>Validated ({step.count} times)</td>
                              : step.state === 2 ?
                                <td className='p-4 text-yellow-500 font-bold'>In progress</td>
                              :
                                <td className='p-4'>Pending</td>
                            }
                          </tr>
                        ))
                      }
                      </tbody>
                    </table>
                  </div>

                  <div className="h-full w-1/2 flex flex-col gap-4">
                    {/* Current step */}
                    <div className="bg-gray-200 p-4 text-center h-1/2 flex flex-col justify-center items-center rounded-md">
                      <p className="text-xl">Current step:</p>
                      <p className="text-3xl">
                        {
                          currentStep ? currentStep.content : 'None'
                        }
                      </p>
                    </div>

                    {/* Total cleaning duration */}
                    <div className="bg-gray-200 p-4 text-xl h-1/2 flex items-center justify-center text-center rounded-md">
                      <p>Cleaning operations: {totalCleaningActions} operation(s) ({formatSecondsToMinutesSeconds(totalCleaningDuration)})</p>
                    </div>
                  </div>
                </div>


                {/* Analysis animation / Progress */}
                <div className="w-full h-1/6 flex justify-center items-center">
                  <div className="flex flex-col justify-center items-center text-center">
                    <style>{`
                      @keyframes bounceScale {
                        0%, 100% {
                          transform: scale(1);
                        }
                        50% {
                          transform: scale(1.15);
                        }
                      }
                    `}</style>
                    <ArrowPathIcon className="animate-spin w-12 h-12 text-perception-blue"/>
                    <p className="text-xl text-perception-blue">Analysis in progress</p>
                    {/*<p className="text-3xl text-gray-300">No error detected</p>*/}
                  </div>

                  <div className="w-full h-full flex-col flex items-center justify-center">
                    <p className="text-left">Progress: {progress}%</p>
                    <div className="w-full bg-gray-200 h-3 rounded-full overflow-hidden">
                      <div
                        className={`bg-perception-blue h-full rounded-full animate-[bounceScale_3s_ease-in-out_infinite]`}
                        style={{width: `${progress}%`}}
                      />
                    </div>
                  </div>
                </div>

                {/* Logos */}
                <div className="h-[50px] pb-0 flex justify-center items-center gap-4 relative">
                  <img src={LogoPerception} alt="logo-perception" className="max-w-[200px] max-h-[60px]" />
                  <span className="text-2xl">x</span>
                  <img src={LogoTAG} alt="logo-perception" className="max-w-[200px] max-h-[60px]" />

                  {/* Report an error */}
                  <div className="flex justify-center absolute right-0">
                    <button className="bg-error text-white p-4 rounded-sm" onClick={handleRaiseError}>Report an error</button>
                  </div>
                </div>

              </div>
            </div>
          :
            <>
              <h2 className='my-6 text-3xl font-semibold'>Select a manufacturing process</h2>
              <div className='flex flex-col gap-4 w-80 '>
                <InputSelect
                  className='py-3'
                  items={patterns}
                  onChange={(pattern) => setSelectedPattern(pattern)}
                  itemToString={(pattern) => pattern?.name}
                  placeholder='Select a manufacturing process'
                  category='secondary'
                  autoComplete='off'
                />
              </div>
              <div className='my-6'>
                <Button type='submit' category='primary-btn' size='small' onClick={handleStart}>
                  Start
                </Button>
              </div>
            </>
        }
      </div>

      {
        synthesis &&
          <div className="absolute top-0 left-0 w-full h-full p-20 bg-black/50 backdrop-blur-sm">
            <div className="relative bg-white h-full rounded-sm flex flex-col justify-between">
              <div className="absolute top-0 right-0 cursor-pointer" onClick={(e) => handleCloseSynthesis(e)}>
                <XMarkIcon className="w-16 h-16 text-gray-600 m-4"/>
              </div>
              <h1 className="bg-gray-200 text-center text-3xl p-8">Production summary</h1>
              <div className="h-full flex justify-center items-center overflow-auto">
                <div className="w-full h-full flex flex-col justify-center items-center text-3xl">
                  {
                    synthesis?.missingSteps?.length === 0 ?
                      <>
                        <CheckIcon className="w-48 h-48 text-perception-success-300"/>
                        <p>No issue detected</p>
                      </>
                      :
                      <div className="flex flex-col justify-around items-center h-full">
                        <div className="flex flex-col items-center">
                          <ExclamationCircleIcon className="w-36 h-36 text-perception-warn-500"/>
                          <p className="text-center">Verification required on certain steps</p>
                        </div>
                        <div className="flex flex-col items-start gap-4">
                          {
                            hasBackwork ?
                              <div className="flex rounded-sm bg-yellow-50 p-4">
                                <div className="shrink-0">
                                  <ExclamationTriangleIcon aria-hidden="true" className="size-5 text-yellow-400" />
                                </div>
                                <div className="ml-3">
                                  <h3 className="text-sm font-medium text-yellow-800">A step backwards was necessary</h3>
                                  <div className="mt-2 text-sm text-yellow-700">
                                    <p>Operations had to be performed several times to respond to a detected problem.</p>
                                  </div>
                                </div>
                              </div>
                            :
                              <div className="flex rounded-sm bg-green-50 p-4">
                                <div className="shrink-0">
                                  <CheckCircleIcon aria-hidden="true" className="size-5 text-green-400" />
                                </div>
                                <div className="ml-3">
                                  <h3 className="text-sm font-medium text-green-800">No backtracking detected</h3>
                                  <div className="mt-2 text-sm text-green-700">
                                    <p>No operation required a review of operations already done.</p>
                                  </div>
                                </div>
                              </div>
                          }
                          <div className="flex rounded-sm bg-blue-50 p-4">
                            <div className="shrink-0">
                              <InformationCircleIcon aria-hidden="true" className="size-5 text-blue-400" />
                            </div>
                            <p className="text-sm text-blue-700 ml-3">Total cleaning duration: {formatSecondsToMinutesSeconds(totalCleaningDuration)}</p>
                          </div>
                        </div>
                      </div>
                  }
                </div>
                <div className="w-full h-full overflow-auto p-4">
                  <table>
                    <thead>
                    <tr className="bg-gray-200">
                      <th className='p-4 text-left'>Step</th>
                      <th className='p-4 text-left'>Analysis</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      stepsList.map((step, index) => (
                        <tr key={step.index}>
                          <td className='p-4'>{step.content}</td>
                          {
                            step.state === 1 && step.count === 1 ?
                              <td className='p-4 text-green-600 font-bold'>Step validated</td>
                              : step.state === 1 && step.count > 1 ?
                                <td className='p-4 text-yellow-500 font-bold'>Check needed (Validated {step.count} times)</td>
                              : index < confidences.length && confidences[index] > 0.3 ?
                                <td className='p-4 text-yellow-500 font-bold'>Check needed</td>
                                :
                                <td className='p-4 text-red-500 font-bold'>Check needed (strongly advised)</td>
                          }
                        </tr>
                      ))
                    }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex justify-center bg-gray-200 p-4">
                <button className="bg-error text-white p-4 rounded-sm" onClick={handleRaiseError}>Report an error</button>
              </div>
            </div>
          </div>
      }
    </div>
  );
};
