import { useEffect, useRef, useState } from "react";
import { postData, useGetData } from "../../services/api/api-tools";
import LogoPerception from '../../assets/images/logos/logo-blue-black.png';
import LogoLISI from '../../assets/images/logos/LISI_Logo.png';
import { useSocket } from "../../contexts/SocketContext";
import { useAlert } from "../../contexts/AlertContext";
import { Loader } from "../../components/loading/Loader";
import { formatSecondsToMinutesSeconds } from "../../helpers/date/date-helper";

export const LISILivePage = () => {

  const { socket, subscribeToEvent, unsubscribeFromEvent, sendEvent } = useSocket();
  const { showAlert } = useAlert();

  const { data: patterns } = useGetData('patterns', `patterns`);

  const [selectedPattern, setSelectedPattern] = useState(null);
  const [isStarted, setIsStarted] = useState(true);
  const [currentData, setCurrentData] = useState({});
  const [showSynthesis, setShowSynthesis] = useState(false);

  const lockedData = useRef(false);

  /* Temporary useEffect to automatically use the first pattern */
  useEffect(() => {
    if (!patterns) return;
    if (patterns?.length === 0) return;
    setSelectedPattern(patterns[0]);
  }, [patterns]);

  const handleStart = () => {
    if(selectedPattern !== null)
      setIsStarted(true);

    /* Send pattern data to the engine */
    // sendEvent('selected_pattern', selectedPattern);
  };

  const resetInterface = () => {
    setCurrentData([]);
    lockedData.current = false;
    handleStart();
  };

  useEffect(() => {
    if (!isStarted) return;
    if (!socket) return;

    const handleReceivedData = (data) => {
      if (lockedData.current) return;
      setCurrentData(prevData => ({...prevData, ...data}));
    };

    subscribeToEvent('current_data', handleReceivedData);

    return () => {
      unsubscribeFromEvent('current_data', handleReceivedData);
    };
  }, [socket, isStarted]);

  const handleRaiseError = () => {
    console.log('New error raised.');
    postData('live_errors', {date: Math.floor(Date.now() / 1000), pattern: `/api/patterns/${selectedPattern?.id}`});
    showAlert('success', 'Le manquement a bien été remonté.')
  };

  const stopProcess = () => {
    lockedData.current = true;
    setShowSynthesis(true);
    setTimeout(resetInterface, 30000);
  };

  return (
    <div className='h-full overflow-hidden bg-perception-black-500'>
      <div className='flex flex-col items-center justify-center w-full h-full'>
        {
          isStarted && selectedPattern !== null ?
            <div className="w-full h-full flex gap-8 text-white text-3xl">

              {/* Left column */}
              <div className="w-1/2 h-full flex flex-col justify-around p-8 pr-0 gap-8">
                {/* Header with selected pattern name */}
                {/*<div className="bg-perception-blue p-4 text-white text-center relative rounded-md">*/}
                {/*  <div className="absolute top-0 h-full left-0 flex justify-center">*/}
                {/*    <button className="text-white p-4 rounded-sm flex items-center" onClick={() => window.location.reload()}>*/}
                {/*      <ArrowUturnLeftIcon aria-hidden="true" className="size-5 mr-2" /> Retour*/}
                {/*    </button>*/}
                {/*  </div>*/}

                {/*  <p className="text-xl">Gamme sélectionnée :</p>*/}
                {/*  <p className="text-3xl">*/}
                {/*    {selectedPattern.name}*/}
                {/*  </p>*/}
                {/*</div>*/}

                {/* Pieces number */}
                <div className="h-1/6 bg-perception-blue p-8 relative rounded-md flex items-center">
                  Pièces dans le four : {currentData?.pieces_count !== undefined ? currentData.pieces_count : '-'}
                </div>

                {/* Heater durations */}
                <div className="h-4/6 justify-between items-center bg-perception-gray-800 rounded-md overflow-auto">
                  <table className="w-full">
                    <thead>
                    <tr className="bg-perception-gray-600">
                      <th className='px-8 py-4 text-left'>#</th>
                      <th className="px-8 py-4 text-left">Durée de chauffe</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      currentData?.pieces_in_oven !== undefined && currentData.pieces_in_oven.map((piece, index) => (
                        <tr key={index}>
                          <td className='px-8 py-4'>{index + 1}</td>
                          <td className='px-8 py-4'>{
                            formatSecondsToMinutesSeconds(piece.time_in_oven)
                          }</td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </table>
                </div>

                {/* Transfer time */}
                <div className="h-1/6 flex items-center p-8 bg-perception-gray-800 rounded-md">
                  Durée de transfert : {currentData?.transfer_duration !== undefined && currentData?.transfer_duration !== null ?
                  Math.ceil(currentData.transfer_duration) + 's' : '-'}
                </div>
              </div>

              {/* Right column */}
              <div className="w-1/2 h-full flex flex-col gap-8 p-8 pl-0">
                <div className="h-5/6 flex flex-col gap-8">
                  {/* Top lubrication */}
                  <div className={`h-1/3 p-8 relative rounded-md flex items-center justify-center
                    ${currentData?.upper_lubrication_done ? 'bg-green-500' : 'bg-perception-gray-800'}`}>
                    {
                      currentData?.upper_lubrication_done === true ?
                        <>Lubrification haute faite</>
                      :
                        <>Lubrification haute non réalisée</>
                    }
                    {/*<div className="absolute top-4 left-4 flex justify-center">*/}
                    {/*  <button className="bg-perception-blue text-white p-4 rounded-md flex items-center" onClick={handleStart}>*/}
                    {/*    <PlayIcon aria-hidden="true" className="size-5 mr-2" /> Démarrer*/}
                    {/*  </button>*/}
                    {/*</div>*/}

                    {/*<div className="absolute top-4 right-4 flex justify-center">*/}
                    {/*  <button className="bg-error text-white p-4 rounded-md flex items-center" onClick={stopProcess}>*/}
                    {/*    <StopIcon aria-hidden="true" className="size-5 mr-2" /> STOP*/}
                    {/*  </button>*/}
                    {/*</div>*/}
                  </div>

                  {/* Bottom lubrication */}
                  <div className={`h-1/3 p-8 relative rounded-md flex items-center justify-center
                    ${currentData?.lower_lubrication_done ? 'bg-green-500' : 'bg-perception-gray-800'}`}>
                    {
                      currentData?.lower_lubrication_done === true ?
                        <>Lubrification basse faite</>
                        :
                        <>Lubrification basse non réalisée</>
                    }
                  </div>

                  {/* Safety stand */}
                  <div className={`h-1/3 p-8 relative rounded-md flex items-center justify-center
                    ${currentData?.safety_stand ? 'bg-green-500' : 'bg-perception-gray-800'}`}>
                    {
                      currentData?.safety_stand === true ?
                        <>Béquille de sécurité présente</>
                        :
                        <>Béquille de sécurité absente</>
                    }
                  </div>
                </div>

                {/* Logos */}
                <div className="h-1/6 p-8 flex justify-center items-center text-xl gap-4 text-black">
                  <img src={LogoPerception} alt="logo-perception" className="max-w-[200px] max-h-[60px]" />
                  x
                  <img src={LogoLISI} alt="logo-LCA" className="max-w-[200px] max-h-[60px]" />
                </div>

                {/*<div className="flex justify-center">*/}
                {/*  <button className="bg-error text-white p-4 rounded-sm" onClick={handleRaiseError}>Déclarer un manquement</button>*/}
                {/*</div>*/}
              </div>
            </div>
            :
            <>
              <Loader category="transparent"/>
              {/*<h2 className='my-6 text-3xl font-semibold'>Choisir une gamme</h2>*/}
              {/*<div className='flex flex-col gap-4 w-80 '>*/}
              {/*  <InputSelect*/}
              {/*    className='py-3'*/}
              {/*    items={patterns}*/}
              {/*    onChange={(pattern) => setSelectedPattern(pattern)}*/}
              {/*    itemToString={(pattern) => pattern?.name}*/}
              {/*    placeholder='Choisir une gamme'*/}
              {/*    category='secondary'*/}
              {/*    autoComplete='off'*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div className='my-6'>*/}
              {/*  <Button type='submit' category='primary-btn' size='small' onClick={handleStart}>*/}
              {/*    Démarrer*/}
              {/*  </Button>*/}
              {/*</div>*/}
            </>
        }
      </div>
    </div>
  );
};
