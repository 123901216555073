import { useState } from "react";
import { patchData } from "../../../../../services/api/api-tools";
import { useAlert } from "../../../../../contexts/AlertContext";

export const PatternDataPanel = ({ patternData }) => {
  return (
    <div className='h-full'>
      {patternData?.length === 0 &&
        <div className='h-full flex flex-col justify-center items-center text-center'>
          <div className='text-4xl material-icons text-perception-black-700'>info</div>
          <div className='text-perception-gray-700'>Pas de données spécifiques à afficher</div>
        </div>
      }
      <div className="flex flex-col gap-2">
        {patternData?.filter((data) => data.name !== 'glueing_duration')?.sort((a, b) => a.id - b.id)?.map((data) => (
          <PatternDataRow
            key={data.id}
            data={data}
          />
        ))}
      </div>
    </div>
  );
};

const PatternDataRow = ({ data }) => {
  const { showAlert } = useAlert();
  const [value, setValue] = useState(data.value);

  const handleValueChange = (e) => {
    const newValue = parseInt(e.target.value);
    setValue(newValue);
  };

  const saveValueChanged = async () => {
    try {
      let path = `pattern_datas/${data.id}`;
      let body = { value: value.toString() };
      const response = await patchData(path, body);
      if (response) {
        showAlert('success', 'Les informations ont été mises à jour.');
      } else {
        showAlert('warning', 'Oops! An error occurred.');
      }
    } catch (err) {
      console.error('Something bad happened:', err);
    }
  };

  return (
    <div className='flex items-center justify-between gap-2 text-xs text-center'>
      <div className={`uppercase`}>{data.display_text}</div>
      <div>
        <input type='text' value={value} onChange={handleValueChange} onBlur={saveValueChanged}
               className='w-12 text-right bg-perception-gray-800 px-1 text-sm focus:outline-none'/>
      </div>
    </div>
  );
};